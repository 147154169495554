<div class="parent">
  <header>

  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <footer>

  </footer>
</div>

